$(
	 function() {
		 app.assignNumberControl();
		 app.setSelectedLink();
		 app.addRegisterCheckBoxes();
		 app.attachSvgEvents();
		 app.makeSocialButtons();
		 app.setBackUrls();
		 app.addCoverSpan();
		 app.saveProductListInfo();
		 app.correctProductList();
         app.topMenu.init();
         app.hamburger.init({test: 1});
		 if(!app.isMobile()) {
			 $( window ).scroll(
				 function(){
					 app.floatingHeader();
				 }
			 );
			 app.floatingHeader();
		 }
		 app.assignProductOver();
		 app.initializeScrollButton();

         $('._ > .top > .center-part').addClass('schema-brd-color');

         app.fixTopPart();
         $(window).resize(
         	function() {
				app.fixTopPart();
			}
		 )
	 }
);

var app = {
	_vars: {
		topWarnInfo: {
			'._ > .top': 'margin-top',
			'_data': {
				onResize: function(sel, data) {
					app.fixTopPart();
					return false;
				}
			}
		},
		v: 2
	},
    _VERSION: 2,
    _VERSION_TRANS_NOT_COMPLETED: true,
	wishListButtonPlaceSelector: '._ .product-list2 div.item .div-prod-btn:not(.heart-added)',
	wishListButtonHTML: '<a class="prod-btn heart"></a>',
	indexLinkPos : 0,
    fixTop : 130,
	messages : {},
	productAvailableQuantityUrl : null,
	optionsWereInitialized : false,
    balloonScrollTopCorrection: 70,
	doAddPlIcons: function ($item, html) {
		$item.find('> .image').append(html);
		$item.find('.pl-icons > div').addClass('udu__jelly-item');
	},
	fixTopPart: function() {
		var w = parseInt($(window).outerWidth());
		var sel = '._ > .top';
		if (w <= 760) {
			sel = '._ .top .top-line';
			app.utils.setStyle('._ > .top', 'margin-top', 0, true);
		}
		var h = app.utils.getHeight(sel + ', .dev-mode-warning');
		app.utils.setStyle('body', 'margin-top', h, true);

		h = app.utils.getHeight('.dev-mode-warning');
		app.utils.setStyle('._ > .top', 'margin-top', h, true);


		return false;
	},
	showSearch : function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	correctProductList: function() {
		$('._ .product-list2 .item .name a').each(
			function() {
				var $div = $(this).closest('.item');
				var href = $(this).attr('href');
				$('<a href="'+href+'"></a>').appendTo($div.find('.category'));
			}
		)
	},
	doBeforePriceSliderShown: function(trg) {
		var color = app.getClassColor('schema-color');
		$('.noUi-connect').css('background-color', color);
	},
	runIndexCarusel: function() {
		app.runCarusel({
			innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
		});

		$('.jcarousel-list li').each(
			function() {
				var href = $(this).find('.image a').attr('href');
				$(this).find('.zoomer').click(
					function() {
						document.location = href;
					}
				)
			}
		);

		var tmp = String(this.getSchemaColor()).match(/(\d+), (\d+), (\d+)/);
		if (tmp && tmp[3]) {
			$('.jcarousel-skin-index .zoomer').css('background-color', 'rgba('+tmp[1]+', '+tmp[2]+', '+tmp[3]+', 0.7)');
		}
	},
	getSchemaColor: function() {
		return app.getClassColor('schema-color');
	}
};